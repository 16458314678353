import actions from './actions';
import staticData from '../../demoData/products.json';

const {
  SINGLE_PRODUCT_BEGIN,
  SINGLE_PRODUCT_SUCCESS,
  SINGLE_PRODUCT_ERR,

  FILTER_PRODUCT_BEGIN,
  FILTER_PRODUCT_SUCCESS,
  FILTER_PRODUCT_ERR,

  SORTING_PRODUCT_BEGIN,
  SORTING_PRODUCT_SUCCESS,
  SORTING_PRODUCT_ERR,

  SEARCH_PRODUCT_BEGIN,
  SEARCH_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_ERR,

  GET_ALL_PRODUCTS_BEGIN,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_ERR,

  GET_PRODUCT_ATTRIBUTE_BEGIN,
  GET_PRODUCT_ATTRIBUTE_SUCCESS,
  GET_PRODUCT_ATTRIBUTE_ERR,
} = actions;

const initialStateFilter = {
  data: staticData,
  loading: false,
  error: null,
};

const initialState = {
  data: staticData,
  loading: false,
  error: null,
};

const productReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILTER_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case FILTER_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case FILTER_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case SORTING_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SORTING_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SORTING_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const SingleProductReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SINGLE_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SINGLE_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const searchProductReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEARCH_PRODUCT_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SEARCH_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case SEARCH_PRODUCT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const getAllProductReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_ALL_PRODUCTS_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case GET_ALL_PRODUCTS_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const getProductAttributeReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PRODUCT_ATTRIBUTE_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case GET_PRODUCT_ATTRIBUTE_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case GET_PRODUCT_ATTRIBUTE_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
}

export {
  SingleProductReducer,
  productReducer,
  searchProductReducer,
  getAllProductReducer,
  getProductAttributeReducer,
};

// action types
export const SET_FILTER = 'SET_FILTER';
export const SET_TAB = 'SET_TAB';

// action creators
export const setFilter = (filterState) => {
  return {
    type: SET_FILTER,
    payload: filterState,
  };
};

export const setTab = (tab) => {
  return {
    type: SET_TAB,
    payload: tab,
  };
};

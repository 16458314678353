export const convertToVND = (amount) => {
  const formattedAmount = amount.toLocaleString("vi-VN");
  const amountInVND = `${formattedAmount} đ`;
  return amountInVND;
};

export const timestampToDate = (timestamp) => {
  // Tạo một đối tượng Date từ timestamp (timestamp tính bằng mili giây nên cần nhân 1000)
  const date = new Date(timestamp * 1000);

  // Lấy ngày, tháng, năm từ đối tượng Date
  const day = date.getDate();
  const month = date.getMonth() + 1; // Tháng bắt đầu từ 0 nên cần cộng thêm 1
  const year = date.getFullYear();

  // Định dạng ngày tháng năm thành chuỗi "dd/mm/yyyy"
  const formattedDate = `${day > 9 ? day : `0${day}`}/${month > 9 ? month : `0${month}`}/${year}`;

  return formattedDate;
};

export const timeAgo = (previousTimeInSeconds) => {
  const previousTime = new Date(previousTimeInSeconds);
  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - previousTime.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30); // Số ngày trung bình trong một tháng

  if (months > 0) {
    return `${months} tháng trước`;
  } else if (days > 0) {
    return `${days} ngày trước`;
  } else if (hours > 0) {
    return `${hours} giờ trước`;
  } else if (minutes > 0) {
    return `${minutes} phút trước`;
  } else {
    return `${seconds} giây trước`;
  }
}

export const formatDateFromMongo = (date) => {
  // Create a new Date object from the MongoDB date string
  const formattedDate = new Date(date);

  // Extract year, month, day, hours, and minutes
  const year = formattedDate.getFullYear();
  const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
  const day = ('0' + formattedDate.getDate()).slice(-2);
  const hours = ('0' + formattedDate.getHours()).slice(-2);
  const minutes = ('0' + formattedDate.getMinutes()).slice(-2);

  // Assemble the formatted date string
  const formattedString = `${year}/${month}/${day} ${hours}:${minutes} `;

  return formattedString;
}
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { API } from '../../config/api';

const { 
  getNotificationListBegin,
  getNotificationListSuccess,
  getNotificationListErr,

  readNotificationBegin, 
  readNotificationSuccess, 
  readNotificationErr 
} = actions;

const getNotificationList = (pageSize, pageNumber) => {
  return async (dispatch) => {
    try {
      dispatch(getNotificationListBegin());
      const response = await DataService.get(`${API.userNotification.getAllUserNotification}?page=${pageNumber ?? 1}&limit=${pageSize ?? 20}`);
      dispatch(getNotificationListSuccess(response.data));
    } catch (err) {
      dispatch(getNotificationListErr(err));
    }
  };
};

const readNotification = (pageSize, pageNumber) => {
  return async (dispatch) => {
    try {
      console.log('readNotificationList', pageSize, pageNumber);
      dispatch(readNotificationBegin());
      const response = await DataService.get(`${API.userNotification.readNotification}`);
      dispatch(readNotificationSuccess(response.data));
    } catch (err) {
      dispatch(readNotificationErr(err));
    }
  };
};

const updateWebSocketData = (data) => {
  console.log('updateWebSocketData', data)
  return {
    type: 'UPDATE_WEBSOCKET_DATA',
    payload: data,
  };
};

export { getNotificationList, readNotification, updateWebSocketData };

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const getItem = (key) => {
  const data = Cookies.get(key);

  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

const setItem = (key, value) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
  return Cookies.set(key, stringify);
};

const removeItem = (key) => {
  Cookies.remove(key);
};

const getRole = () => {
  const key = 'access_token';
  const data = Cookies.get(key);
  const user = jwtDecode(data);

  try {
    return user.role;
  } catch (err) {
    return data;
  }
};

const getUserId = () => {
  const key = 'access_token';
  const data = Cookies.get(key);
  const user = jwtDecode(data);

  try {
    return user.id;
  } catch (err) {
    return data;
  }
}

export { getItem, setItem, removeItem, getRole, getUserId };

const actions = {
  GET_NOTIFICATION_LIST: 'GET_NOTIFICATION_LIST',
  GET_NOTIFICATION_LIST_SUCCESS: 'GET_NOTIFICATION_LIST_SUCCESS',
  GET_NOTIFICATION_LIST_ERR: 'GET_NOTIFICATION_LIST_ERR',

  READ_NOTIFICATION_BEGIN: 'READ_NOTIFICATION_BEGIN',
  READ_NOTIFICATION_SUCCESS: 'READ_NOTIFICATION_SUCCESS',
  READ_NOTIFICATION_ERR: 'READ_NOTIFICATION_ERR',
  UPDATE_WEBSOCKET_DATA: 'UPDATE_WEBSOCKET_DATA',

  getNotificationListBegin: () => {
    return {
      type: actions.GET_NOTIFICATION_LIST,
    };
  },

  getNotificationListSuccess: (data) => {
    return {
      type: actions.GET_NOTIFICATION_LIST_SUCCESS,
      data,
    };
  },

  getNotificationListErr: (err) => {
    return {
      type: actions.GET_NOTIFICATION_LIST_ERR,
      err,
    };
  },

  readNotificationBegin: () => {
    return {
      type: actions.READ_NOTIFICATION_BEGIN,
    };
  },

  readNotificationSuccess: (data) => {
    return {
      type: actions.READ_NOTIFICATION_SUCCESS,
      data,
    };
  },

  readNotificationErr: (err) => {
    return {
      type: actions.READ_NOTIFICATION_ERR,
      err,
    };
  },

  updateWebSocketData: (data) => {
    return {
      type: actions.UPDATE_WEBSOCKET_DATA,
      data
    }
  }
};

export default actions;

/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    login: '/login',
    signUp: '/signup',
  },
  order: {
    createOrder: '/order-management',
    getAllOrderManagement: '/order-management',
    getSingleOrderManagement: '/order-management/',
    updateOrderManagement: '/order-management/',
    deleteOrderManagement: '/order-management/{0}',
    updateOrderProgress: '/order-management/{0}/progress',
    updateOrderTiktokProgress: '/order-management/tiktok-progress',
    assign: '/order-management/assign',
    deleteOrder: '/order-management/',
    multipleSubOrder: '/order-management/sub/',
  },
  userNotification: {
    getAllUserNotification: '/user-notification',
    readNotification: '/user-notification/read',
  },
  users: {
    getAllUser: '/users',
    getSingleUser: '/users/',
    addUser: '/users',
    updateUser: '/users/',
    deleteUser: '/users/',
    getEmployee: '/admin/users/employee',
  },
  products: {
    searchProduct: '/product/search',
    getAllProducts: '/product',
    getProductAttribute: '/product/product-attributes',
    singleProduct: '/product/',
  },
  upload: {
    uploadAvatar: '/upload/upload-file'
  }
};

export { API };

import actions from './actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
}

const {
  GET_ALL_USERS_BEGIN,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERR,
  GET_SINGLE_USER_BEGIN,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_ERR,
  SEARCH_USERS_BEGIN,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_ERR,
  ADD_USER_BEGIN,
  ADD_USER_SUCCESS,
  ADD_USER_ERR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERR,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERR,
  UPLOAD_AVATAR_BEGIN,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_ERR,

  GET_EMPLOYEE_BEGIN,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_ERR,
} = actions;

const getAllUserReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_ALL_USERS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
      };
    case GET_ALL_USERS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
}

const getSingleUserReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SINGLE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SINGLE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
      };
    case GET_SINGLE_USER_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
}

const searchUserReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEARCH_USERS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
      };
    case SEARCH_USERS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
}

const addUserReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
      };
    case ADD_USER_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
}

const updateUserReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
      };
    case UPDATE_USER_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
}

const deleteUserReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DELETE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
      };
    case DELETE_USER_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
}

const uploadAvatarReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPLOAD_AVATAR_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
      };
    case UPLOAD_AVATAR_ERR:
      return {
        ...state,
        loading: false,
        error:
          err,
      }
    default:
      return state;
  }
}

const getEmployeeReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_EMPLOYEE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: data,
      };
    case GET_EMPLOYEE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
}

export {
  getAllUserReducer,
  getSingleUserReducer,
  searchUserReducer,
  addUserReducer,
  updateUserReducer,
  deleteUserReducer,
  uploadAvatarReducer,
  getEmployeeReducer,
};

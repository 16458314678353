const actions = {
  GET_ALL_ORDER_MANAGEMENT_BEGIN: 'GET_ALL_ORDER_MANAGEMENT_BEGIN',
  GET_ALL_ORDER_MANAGEMENT_SUCCESS: 'GET_ALL_ORDER_MANAGEMENT_SUCCESS',
  GET_ALL_ORDER_MANAGEMENT_ERR: 'GET_ALL_ORDER_MANAGEMENT_ERR',

  GET_ORDER_HISTORY_BEGIN: 'GET_ORDER_HISTORY_BEGIN',
  GET_ORDER_HISTORY_SUCCESS: 'GET_ORDER_HISTORY_SUCCESS',
  GET_ORDER_HISTORY_ERR: 'GET_ORDER_HISTORY_ERR',

  SINGLE_ORDER_MANAGEMENT_BEGIN: 'SINGLE_ORDER_MANAGEMENT_BEGIN',
  SINGLE_ORDER_MANAGEMENT_SUCCESS: 'SINGLE_ORDER_MANAGEMENT_SUCCESS',
  SINGLE_ORDER_MANAGEMENT_ERR: 'SINGLE_ORDER_MANAGEMENT_ERR',

  FILTER_ORDER_MANAGEMENT_BEGIN: 'FILTER_ORDER_MANAGEMENT_BEGIN',
  FILTER_ORDER_MANAGEMENT_SUCCESS: 'FILTER_ORDER_MANAGEMENT_SUCCESS',
  FILTER_ORDER_MANAGEMENT_ERR: 'FILTER_ORDER_MANAGEMENT_ERR',

  SORTING_ORDER_MANAGEMENT_BEGIN: 'SORTING_ORDER_MANAGEMENT_BEGIN',
  SORTING_ORDER_MANAGEMENT_SUCCESS: 'SORTING_ORDER_MANAGEMENT_SUCCESS',
  SORTING_ORDER_MANAGEMENT_ERR: 'SORTING_ORDER_MANAGEMENT_ERR',

  SYNC_ORDERS_BEGIN: 'SYNC_ORDERS_BEGIN',
  SYNC_ORDERS_SUCCESS: 'SYNC_ORDERS_SUCCESS',
  SYNC_ORDERS_ERR: 'SYNC_ORDERS_ERR',

  CREATE_ORDER_BEGIN: 'CREATE_ORDER_BEGIN',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_ERR: 'CREATE_ORDER_ERR',

  UPDATE_ORDER_BEGIN: 'UPDATE_ORDER_BEGIN',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_ERR: 'UPDATE_ORDER_ERR',

  DELETE_ORDER_BEGIN: 'DELETE_ORDER_BEGIN',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_ERR: 'DELETE_ORDER_ERR',

  UPDATE_ORDER_PROGRESS_BEGIN: 'UPDATE_ORDER_PROGRESS_BEGIN',
  UPDATE_ORDER_PROGRESS_SUCCESS: 'UPDATE_ORDER_PROGRESS_SUCCESS',
  UPDATE_ORDER_PROGRESS_ERR: 'UPDATE_ORDER_PROGRESS_ERR',

  UPDATE_ORDER_TIKTOK_PROGRESS_BEGIN: 'UPDATE_ORDER_TIKTOK_PROGRESS_BEGIN',
  UPDATE_ORDER_TIKTOK_PROGRESS_SUCCESS: 'UPDATE_ORDER_TIKTOK_PROGRESS_SUCCESS',
  UPDATE_ORDER_TIKTOK_PROGRESS_ERR: 'UPDATE_ORDER_TIKTOK_PROGRESS_ERR',

  ASSIGN_ORDER_BEGIN: 'ASSIGN_ORDER_BEGIN',
  ASSIGN_ORDER_SUCCESS: 'ASSIGN_ORDER_SUCCESS',
  ASSIGN_ORDER_ERR: 'ASSIGN_ORDER_ERR',

  GET_MULTIPLE_SUB_ORDER_BEGIN: 'GET_MULTIPLE_SUB_ORDER_BEGIN',
  GET_MULTIPLE_SUB_ORDER_SUCCESS: 'GET_MULTIPLE_SUB_ORDER_SUCCESS',
  GET_MULTIPLE_SUB_ORDER_ERR: 'GET_MULTIPLE_SUB_ORDER_ERR',

  SET_ORDER_DETAIL_DATA: 'SET_ORDER_DETAIL_DATA',
  SET_ORDER_MANAGEMENT_SUMMARY: 'SET_ORDER_MANAGEMENT_SUMMARY',
  SET_ORDER_MANAGEMENT_REPORT: 'SET_ORDER_MANAGEMENT_REPORT',

  getAllOrderManagementBegin: () => {
    return {
      type: actions.GET_ALL_ORDER_MANAGEMENT_BEGIN,
    };
  },

  getAllOrderManagementSuccess: (data) => {
    return {
      type: actions.GET_ALL_ORDER_MANAGEMENT_SUCCESS,
      data,
    };
  },

  getAllOrderManagementErr: (err) => {
    return {
      type: actions.GET_ALL_ORDER_MANAGEMENT_ERR,
      err,
    };
  },

  getOrderHistoryBegin: () => {
    return {
      type: actions.GET_ORDER_HISTORY_BEGIN,
    };
  },

  getOrderHistorySuccess: (data) => {
    return {
      type: actions.GET_ORDER_HISTORY_SUCCESS,
      data,
    };
  },

  getOrderHistoryErr: (err) => {
    return {
      type: actions.GET_ORDER_HISTORY_ERR
    };
  },

  singleOrderManagementBegin: () => {
    return {
      type: actions.SINGLE_ORDER_MANAGEMENT_BEGIN,
    };
  },

  singleOrderManagementSuccess: (data) => {
    return {
      type: actions.SINGLE_ORDER_MANAGEMENT_SUCCESS,
      data,
    };
  },

  singleOrderManagementErr: (err) => {
    return {
      type: actions.SINGLE_ORDER_MANAGEMENT_ERR,
      err,
    };
  },

  filterOrderManagementBegin: () => {
    return {
      type: actions.FILTER_ORDER_MANAGEMENT_BEGIN,
    };
  },

  filterOrderManagementSuccess: (data) => {
    return {
      type: actions.FILTER_ORDER_MANAGEMENT_SUCCESS,
      data,
    };
  },

  filterOrderManagementErr: (err) => {
    return {
      type: actions.FILTER_ORDER_MANAGEMENT_ERR,
      err,
    };
  },

  sortingOrderManagementBegin: () => {
    return {
      type: actions.SORTING_ORDER_MANAGEMENT_BEGIN,
    };
  },

  sortingOrderManagementSuccess: (data) => {
    return {
      type: actions.SORTING_ORDER_MANAGEMENT_SUCCESS,
      data,
    };
  },

  sortingOrderManagementErr: (err) => {
    return {
      type: actions.SORTING_ORDER_MANAGEMENT_ERR,
      err,
    };
  },

  syncOrdersBegin: () => {
    return {
      type: actions.SYNC_ORDERS_BEGIN
    };
  },

  syncOrdersSuccess: (data) => {
    return {
      type: actions.SYNC_ORDERS_SUCCESS,
      data
    };
  },

  syncOrdersErr: (err) => {
    return {
      type: actions.SYNC_ORDERS_ERR,
      err,
    };
  },

  createOrderBegin: () => {
    return {
      type: actions.CREATE_ORDER_BEGIN
    };
  },

  createOrderSuccess: (data) => {
    return {
      type: actions.CREATE_ORDER_SUCCESS,
      data
    };
  },

  createOrderErr: (err) => {
    return {
      type: actions.CREATE_ORDER_ERR,
      err,
    };
  },

  updateOrderBegin: () => {
    return {
      type: actions.UPDATE_ORDER_BEGIN
    };
  },
  updateOrderSuccess: (data) => {
    return {
      type: actions.UPDATE_ORDER_SUCCESS,
      data
    };
  },
  updateOrderErr: (err) => {
    return {
      type: actions.UPDATE_ORDER_ERR,
      err,
    };
  },
  deleteOrderBegin: () => {
    return {
      type: actions.DELETE_ORDER_BEGIN
    };
  },
  deleteOrderSuccess: (data) => {
    return {
      type: actions.DELETE_ORDER_SUCCESS,
      data
    };
  },
  deleteOrderErr: (err) => {
    return {
      type: actions.DELETE_ORDER_ERR,
      err,
    };
  },

  updateOrderProgressBegin: () => {
    return {
      type: actions.UPDATE_ORDER_PROGRESS_BEGIN
    };
  },

  updateOrderProgressSuccess: (data) => {
    return {
      type: actions.UPDATE_ORDER_PROGRESS_SUCCESS,
      data
    };
  },

  updateOrderProgressErr: (err) => {
    return {
      type: actions.UPDATE_ORDER_PROGRESS_ERR,
      err,
    };
  },

  updateOrderTiktokProgressBegin: () => {
    return {
      type: actions.UPDATE_ORDER_TIKTOK_PROGRESS_BEGIN
    };
  },

  updateOrderTiktokProgressSuccess: (data) => {
    return {
      type: actions.UPDATE_ORDER_TIKTOK_PROGRESS_SUCCESS,
      data
    };
  },

  updateOrderTiktokProgressErr: (err) => {
    return {
      type: actions.UPDATE_ORDER_TIKTOK_PROGRESS_ERR,
      err,
    };
  },

  assignOrderBegin: () => {
    return {
      type: actions.ASSIGN_ORDER_BEGIN
    };
  },

  assignOrderSuccess: (data) => {
    return {
      type: actions.ASSIGN_ORDER_SUCCESS,
      data
    };
  },

  assignOrderErr: (err) => {
    return {
      type: actions.ASSIGN_ORDER_ERR,
      err,
    };
  },

  getMultipleSubOrderBegin: () => {
    return {
      type: actions.GET_MULTIPLE_SUB_ORDER_BEGIN
    };
  },

  getMultipleSubOrderSuccess: (data) => {
    return {
      type: actions.GET_MULTIPLE_SUB_ORDER_SUCCESS,
      data
    };
  },

  getMultipleSubOrderErr: (err) => {
    return {
      type: actions.GET_MULTIPLE_SUB_ORDER_ERR,
      err,
    };
  },

  setOrderManagementReport: (data) => {
    return {
      type: actions.SET_ORDER_MANAGEMENT_REPORT,
      data,
    };
  },

  setOrderManagementSummary: (data) => {
    return {
      type: actions.SET_ORDER_MANAGEMENT_SUMMARY,
      data,
    };
  },

  setOrderDetailData: (data) => {
    return {
      type: actions.SET_ORDER_DETAIL_DATA,
      data,
    };
  }
};

export default actions;

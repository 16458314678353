import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const AddUser = lazy(() => import('../../container/users/AddUsers'));
const DataTable = lazy(() => import('../../container/users/UserListDataTable'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="/*" element={<DataTable />} />
      <Route path="add-user/*" element={<AddUser />} />
      <Route path="edit-user/*" element={<AddUser isEditUser={true} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;

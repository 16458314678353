import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}`;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});

const optionalHeader = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

const uploadHeader = {
  'Content-Type': 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};

class DataService {
  static async get(path = '') {
    return axios.get(`${API_ENDPOINT}${path}`, {
      headers: { ...authHeader() },
    });
  }

  static async post(path = '', data = {}, optionalHeader = {}) {
    return axios.post(`${API_ENDPOINT}${path}`, data, {
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static async patch(path = '', data = {}) {
    return axios.patch(`${API_ENDPOINT}${path}`, data, {
      headers: { ...authHeader() },
    });
  }

  static async put(path = '', data = {}) {
    return axios.put(`${API_ENDPOINT}${path}`, data, {
      headers: { ...authHeader() },
    });
  }

  static async delete(path = '', data = {}) {
    return axios.delete(`${API_ENDPOINT}${path}`, {
      headers: { ...authHeader() },
      data,
    });
  }

  static async upload(path = '', data = {}) {
    return axios.post(`${API_ENDPOINT}${path}`, data, {
      headers: { ...uploadHeader, ...authHeader() },
    });
  }
}

export { DataService };

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from "../../components/page-headers/page-headers";
import { Link } from 'react-router-dom';
import { Row, Col } from "antd";
import { GlobalUtilityStyle } from "../styled";
import UilEllipsisH from '@iconscout/react-unicons/icons/uil-ellipsis-h';
import { Dropdown } from '../../components/dropdown/dropdown';
import { ActivityContents } from "./style";
import UilInbox from '@iconscout/react-unicons/icons/uil-inbox';
import { getNotificationList } from "../../redux/notification/actionCreator";
import { timeAgo } from "../../helper/format";

function NotificationList() {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('Gọi dispatch get notification data');
    dispatch(getNotificationList(100, 1));
  }, []);

  const { rtl, loading, notifications, unreadNotification, newestNotification } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      loading: state.notification.loading,
      notifications: state.notification.data,
      unreadNotification: state.notification.unreadNotification,
      newestNotification: state.notification?.websocketData
    };
  });

  const [state, setState] = useState({
    taskTab: 'all',
  });
  const [notificationData, setNotification] = useState([]);

  useEffect(() => {
    console.log('notifications', notifications)
    // Get 20 noti lastest
    const data = notifications?.records;
    setNotification(data);
  }, [notifications]);

  const PageRoutes = [
    {
      path: "/admin",
      breadcrumbName: "Dashboard",
    },
    {
      path: "",
      breadcrumbName: "Notification",
    },
  ];

  const handleTabActivation = (value, event) => {
    event.preventDefault();
    setState({
      ...state,
      taskTab: value,
    });
  };

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Tất cả thông báo"
        className="flex items-center justify-between px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <div className="min-h-[715px] lg:min-h-[580px] flex-1 h-auto px-8 xl:px-[15px] pb-[30px] bg-transparent">
        <GlobalUtilityStyle>
          <Row gutter={15}>
            <Col xs={24} className="mb-[25px]">
              <div className="bg-white dark:bg-white10 m-0 p-0 text-theme-gray dark:text-white60 text-[15px] rounded-10 relative">
                <div className="h-[60px] px-[25px] text-dark dark:text-white87 font-medium text-[17px] flex flex-wrap items-center justify-between sm:flex-col sm:h-auto sm:mb-[15px]">
                  <h1 className="mb-0 inline-flex items-center py-[18px] sm:pb-[5px] overflow-hidden whitespace-nowrap text-ellipsis text-[18px] font-semibold">
                    Thông báo
                  </h1>
                  <ul className="flex items-center mb-0">
                    <li>
                      <Link
                        className={
                          state.taskTab === 'all'
                            ? 'inline-flex items-center bg-primary-transparent dark:bg-white10 px-3 h-8 text-primary dark:text-white87 text-13 font-medium rounded-md'
                            : 'inline-flex items-center px-3 h-8 text-light dark:text-white60 hover:text-primary text-13'
                        }
                        onClick={(e) => handleTabActivation('all', e)}
                        to="#"
                      >
                        Tất cả
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          state.taskTab === 'favourite'
                            ? 'inline-flex items-center bg-primary-transparent dark:bg-white10 px-3 h-8 text-primary dark:text-white87 text-13 font-medium rounded-md'
                            : 'inline-flex items-center px-3 h-8 text-light dark:text-white60 dark:hover:text-white hover:text-primary text-13 font-medium rounded-md'
                        }
                        onClick={(e) => handleTabActivation('favourite', e)}
                        to="#"
                      >
                        Đã đọc
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          state.taskTab === 'completed'
                            ? 'inline-flex items-center bg-primary-transparent dark:bg-white10 px-3 h-8 text-primary dark:text-white87 text-13 font-medium rounded-md'
                            : 'inline-flex items-center px-3 h-8 text-light dark:text-white60 dark:hover:text-white hover:text-primary text-13 font-medium rounded-md'
                        }
                        onClick={(e) => handleTabActivation('completed', e)}
                        to="#"
                      >
                        Quan trọng
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="pb-[25px] [&>.relative>.bg-white]:!bg-transparent">
                  <ActivityContents>
                    <div className="p-0 bg-white dark:bg-white10 rounded-10 py-[15px] ">
                      <ul className="m-0">
                        {
                          notificationData?.map((notification, index) => (
                            <li key={notification._id} className="py-[12px] px-[25px] flex items-center gap-[15px] hover:shadow-regular dark:shadow-none transition-[0.3s]">
                              <span className="text-primary bg-primary/[.08] w-[31px] h-[31px] rounded-full flex items-center justify-center">
                                <UilInbox className="w-[14px] h-[14px]" />
                              </span>
                              <div className="flex items-center justify-between flex-1">
                                <div className="flex items-center gap-[20px]">
                                  <img
                                    className="rounded-full max-w-[40px]"
                                    src={require('../../static/img/chat-author/t1.jpg')}
                                    alt=""
                                  />
                                  <p className="mb-0 text-[14px] text-theme-gray dark:text-white60">
                                    <span className="inline-block font-medium text-primary">#</span> {notification?.content}
                                    <span className="block mt-[3px] text-light-extra dark:text-white60">{timeAgo(notification?.createdAt)}</span>
                                  </p>
                                </div>
                                <Link className="text-light-extra dark:text-white60" to="#">
                                  <Dropdown
                                    action={['click']}
                                    content={
                                      <div className=" block bg-white w-[120px] dark:bg-[#1b1e2b] shadow-regular dark:shadow-[0_5px_30px_rgba(1,4,19,.60)] rounded-4">
                                        <Link
                                          className="flex items-center text-theme-gray dark:text-white60 hover:bg-primary-transparent hover:text-primary dark:hover:bg-white10 px-3 py-1.5 text-sm active"
                                          to="#"
                                        >
                                          <span>Hide</span>
                                        </Link>
                                        <Link
                                          className="flex items-center text-theme-gray dark:text-white60 hover:bg-primary-transparent hover:text-primary dark:hover:bg-white10 px-3 py-1.5 text-sm active"
                                          to="#"
                                        >
                                          <span>Delete</span>
                                        </Link>
                                      </div>
                                    }
                                  >
                                    <UilEllipsisH className="w-[24px] h-[24px] m-0" />
                                  </Dropdown>
                                </Link>
                              </div>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </ActivityContents>
                </div>
              </div>
            </Col>
          </Row>
        </GlobalUtilityStyle>
      </div>
    </>


  );
}

export default NotificationList;

import actions from './actions';

const {
  GET_ALL_ORDER_MANAGEMENT_BEGIN,
  GET_ALL_ORDER_MANAGEMENT_SUCCESS,
  GET_ALL_ORDER_MANAGEMENT_ERR,

  GET_ORDER_HISTORY_BEGIN,
  GET_ORDER_HISTORY_SUCCESS,
  GET_ORDER_HISTORY_ERR,

  SINGLE_ORDER_MANAGEMENT_BEGIN,
  SINGLE_ORDER_MANAGEMENT_SUCCESS,
  SINGLE_ORDER_MANAGEMENT_ERR,

  FILTER_ORDER_MANAGEMENT_BEGIN,
  FILTER_ORDER_MANAGEMENT_SUCCESS,
  FILTER_ORDER_MANAGEMENT_ERR,

  SORTING_ORDER_MANAGEMENT_BEGIN,
  SORTING_ORDER_MANAGEMENT_SUCCESS,
  SORTING_ORDER_MANAGEMENT_ERR,

  SYNC_ORDERS_BEGIN,
  SYNC_ORDERS_SUCCESS,
  SYNC_ORDERS_ERR,

  CREATE_ORDER_BEGIN,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERR,

  UPDATE_ORDER_PROGRESS_BEGIN,
  UPDATE_ORDER_PROGRESS_SUCCESS,
  UPDATE_ORDER_PROGRESS_ERR,

  UPDATE_ORDER_BEGIN,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERR,

  DELETE_ORDER_BEGIN,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_ERR,

  UPDATE_ORDER_TIKTOK_PROGRESS_BEGIN,
  UPDATE_ORDER_TIKTOK_PROGRESS_SUCCESS,
  UPDATE_ORDER_TIKTOK_PROGRESS_ERR,

  ASSIGN_ORDER_BEGIN,
  ASSIGN_ORDER_SUCCESS,
  ASSIGN_ORDER_ERR,

  SET_ORDER_MANAGEMENT_SUMMARY,
  SET_ORDER_MANAGEMENT_REPORT,
  SET_ORDER_DETAIL_DATA,
} = actions;

const initialStateFilter = {
  data: [],
  loading: false,
  error: null,
  success: false,
};

const orderSummaryInitialState = {
  // waitShipmentCount: 0,
  // completedCount: 0,
  // cancelledCount: 0,
  // lateCount: 0,
  data: null,
};

const orderReportInitialState = {
  // uncompletedCount: 0,
  // todayOrderCount: 0,
  data: null,
};

const OrderManagementReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FILTER_ORDER_MANAGEMENT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case FILTER_ORDER_MANAGEMENT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case FILTER_ORDER_MANAGEMENT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case SORTING_ORDER_MANAGEMENT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case SORTING_ORDER_MANAGEMENT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case SORTING_ORDER_MANAGEMENT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case GET_ALL_ORDER_MANAGEMENT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case GET_ALL_ORDER_MANAGEMENT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case GET_ALL_ORDER_MANAGEMENT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case UPDATE_ORDER_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
        error: null,
        success: true,
      };
    case UPDATE_ORDER_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    case DELETE_ORDER_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
        error: null,
        success: true,
      };
    default:
      return state;
  }
};

const SingleOrderManagementReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_ORDER_MANAGEMENT_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case SINGLE_ORDER_MANAGEMENT_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      };
    case SINGLE_ORDER_MANAGEMENT_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const syncOrdersReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;

  switch (type) {
    case SYNC_ORDERS_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case SYNC_ORDERS_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      }
    case SYNC_ORDERS_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
}

const getOrderHistoryReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_ORDER_HISTORY_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case GET_ORDER_HISTORY_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
        error: null,
      };
    case GET_ORDER_HISTORY_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const createOrderReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_ORDER_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
        error: null,
        success: true,
      };
    case CREATE_ORDER_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const updateOrderProgressReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_ORDER_PROGRESS_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case UPDATE_ORDER_PROGRESS_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
        error: null,
        success: true,
      }
    case UPDATE_ORDER_PROGRESS_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  };
}

const updateOrderTiktokProgressReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_ORDER_TIKTOK_PROGRESS_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case UPDATE_ORDER_TIKTOK_PROGRESS_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
        error: null,
        success: true,
      }
    case UPDATE_ORDER_TIKTOK_PROGRESS_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  };
}

const updateOrderReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_ORDER_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
        error: null,
        success: true,
      };
    case UPDATE_ORDER_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      }
    default:
      return state;
  }
};

const deleteOrderReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DELETE_ORDER_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
      }
    case DELETE_ORDER_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const assignOrderReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSIGN_ORDER_BEGIN:
      return {
        ...initialStateFilter,
        loading: true,
      };
    case ASSIGN_ORDER_SUCCESS:
      return {
        ...initialStateFilter,
        data,
        loading: false,
        error: null,
        success: true,
      }
    case ASSIGN_ORDER_ERR:
      return {
        ...initialStateFilter,
        error: err,
        loading: false,
      }
    default:
      return state;
  }
}
const setOrderSummaryReducer = (state = orderSummaryInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ORDER_MANAGEMENT_SUMMARY:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};

const setOrderReportReducer = (state = orderReportInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ORDER_MANAGEMENT_REPORT:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};

const setOrderDetailDataReducer = (state = { data: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ORDER_DETAIL_DATA:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};


export {
  SingleOrderManagementReducer,
  OrderManagementReducer,
  syncOrdersReducer,
  getOrderHistoryReducer,
  createOrderReducer,
  updateOrderProgressReducer,
  updateOrderReducer,
  deleteOrderReducer,
  updateOrderTiktokProgressReducer,
  assignOrderReducer,
  setOrderSummaryReducer,
  setOrderReportReducer,
  setOrderDetailDataReducer,
};

import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { createUUID } from '../../helper/generator';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/authentication/login', values);
      if (response.data?.errors) {
        dispatch(loginErr(response.data.errors));
      } else {
        const randomId = createUUID();
        if (!getItem('browserId')) {
          Cookies.set('browserId', randomId);
        }
        Cookies.set('access_token', response.data?.accessToken);
        const user = jwtDecode(response.data?.accessToken);
        Cookies.set('logedIn', true);
        dispatch(loginSuccess(user));
        callback();
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };

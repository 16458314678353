// export const ORDER_PROGRESS_STATUS = {
//   DESIGN: "Thiết kế",
//   CUSTOMER_APPROVE: "Khách duyệt",
//   PRINT: "In ảnh",
//   PACK: "Đóng gói",
//   SHIP: "Đã gửi hàng",
// };


export const TIKTOK_ORDER_STATUS = {
  AWAITING_SHIPMENT: "AWAITING_SHIPMENT",
  AWAITING_COLLECTION: "AWAITING_COLLECTION",
  IN_TRANSIT: "IN_TRANSIT",
  DELIVERED: "DELIVERED",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
};

export const TIKTOK_ORDER_STATUS_VI = {
  UNPAID: "Chưa thanh toán",
  ON_HOLD: "Đang giữ",
  PARTIALLY_SHIPPING: "Giao hàng một phần",
  AWAITING_SHIPMENT: "Chờ vận chuyển",
  AWAITING_COLLECTION: "Chờ lấy hàng",
  IN_TRANSIT: "Đang vận chuyển",
  DELIVERED: "Đã giao hàng",
  COMPLETED: "Hoàn thành",
  CANCELLED: "Đã hủy",
};

export const TIKTOK_ORDER_REQ_STATUS = {
  ALL: 'ALL',
  AWAITING_SHIPMENT: 'AWAITING_SHIPMENT',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  LATE: 'LATE',
}
export const TIKTOK_ORDER_REQ_STATUS_COLOR_CODE = {
  PREPARE_ORDER: '#FA8B0C',
  COMPLETED: '#01B81A',
  CANCELLED: '#FF4D4F',
  LATE: '#FF4D4F',
}

export const TIKTOK_ORDER_STATUS_COLOR_TEXT = {
  UNPAID: "deactivated",
  ON_HOLD: "deactivated",
  PARTIALLY_SHIPPING: "deactivated",
  AWAITING_SHIPMENT: "deactivated",
  AWAITING_COLLECTION: "deactivated",
  IN_TRANSIT: "deactivated",
  DELIVERED: "active",
  COMPLETED: "active",
  CANCELLED: "blocked",
};

export const WEBHOOK_TYPE = {
  ORDER_STATUS_UPDATE: 1,
  REVERSE_UPDATE: 2,
  RECEIVER_ADDRESS_UPDATE: 3,
  PACKAGE_UPDATE: 4,
  PRODUCT_AUDIT_STATUS_UPDATE: 5,
  SELLER_DEAUTHORIZATION: 6,
  AUTH_EXPIRED: 7,
  CANCELLED_ORDER: 11,
  RETURN_OR_REFUND_REQUEST: 12,
}

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 100;

/**
 * Loại đơn hàng
 * 
 * - Đơn bù
 * - Đơn bổ sung
 * - Đơn ngoài
 * - TikTok
 * - Shopee
 */
export const ORDER_TYPE = {
  ORDER_COMPENSATION: 1,
  ORDER_SUPPLEMENT: 2,
  EXTERNAL_ORDER: 3,
  TIKTOK: 4,
  SHOPEE: 5,
  FILTERING: 6
}

export const ORDER_TYPE_BASIC = {
  ORDER_COMPENSATION: 1,
  OTHER: 2,
}

export const ORDER_TYPE_TEXT = {
  1: "Đơn bù",
  2: "Đơn bổ sung",
  3: "Đơn ngoài",
  4: "TikTok",
  5: "Shopee",
}

/**
 * Các trạng thái tiến độ đơn hàng
 * - Gom ảnh
 * - Thiết kế
 * - Khách duyệt
 * - In ảnh
 * - Hoàn thành
 */
export const ORDER_PROGRESS_STATUS = {
  COLLECT_PHOTOS: 0,
  DESIGN: 1,
  REVIEW: 2,
  PRINT: 3,
  PACK: 4,
  DONE: 5
}
export const ORDER_PROGRESS_STATUS_COLOR_CODE = {
  COLLECT_PHOTOS: "#E08F32",
  DESIGN: "#337BD0",
  REVIEW: "#965897",
  PRINT: "#66D2F7",
  PACK: "FFD700",
  DONE: "8FC74D",
}
export const ORDER_PROGRESS_STATUS_VI = {
  COLLECT_PHOTOS: 'Gom ảnh',
  DESIGN: 'Thiết kế',
  REVIEW: 'Đánh giá',
  PRINT: 'In ảnh',
  PACK: 'Đóng gói',
  DONE: 'Hoàn thành',
}

export const USER_STATUS_VI = {
  active: 'Đang hoạt động',
  deactivated: 'Không hoạt động',
  blocked: 'Bị khóa',
}

export const ROLE = {
  admin: 'admin',
  manager: 'manager',
  user: 'user',
}

export const TIKTOK_ORDER_MANAGEMENT_TABS = {
  ALL: 1,
  NEED_SEND: 2,
  SENT: 3,
  COMPLETED: 4,
  WAITING_REFUND: 5,
  CANCELLED: 6,
}

export const TIKTOK_ORDER_MANAGEMENT_TABS_VI = {
  ALL: 'Tất cả',
  NEED_SEND: 'Cần gửi',
  SENT: 'Đã gửi',
  COMPLETED: 'Hoàn thành',
  WAITING_REFUND: 'Yêu cầu hoàn tiền/ Hoàn hàng',
  CANCELLED: 'Đã hủy',
}

export const TIKTOK_ORDER_MANAGEMENT_TABS_STATUS = {
  ALL: [
    TIKTOK_ORDER_STATUS.AWAITING_SHIPMENT,
    TIKTOK_ORDER_STATUS.AWAITING_COLLECTION,
    TIKTOK_ORDER_STATUS.IN_TRANSIT,
    TIKTOK_ORDER_STATUS.DELIVERED,
    TIKTOK_ORDER_STATUS.COMPLETED,
    TIKTOK_ORDER_STATUS.CANCELLED,
    TIKTOK_ORDER_REQ_STATUS.LATE,
  ],
  NEED_SEND: [
    TIKTOK_ORDER_STATUS.AWAITING_SHIPMENT,
    TIKTOK_ORDER_STATUS.AWAITING_COLLECTION,
  ],
  SENT: [
    TIKTOK_ORDER_STATUS.IN_TRANSIT,
    TIKTOK_ORDER_STATUS.DELIVERED,
  ],
  COMPLETED: [
    TIKTOK_ORDER_STATUS.COMPLETED,
  ],
  WAITING_REFUND: [
    // TIKTOK_ORDER_STATUS.CANCELLED,
  ],
  CANCELLED: [
    TIKTOK_ORDER_STATUS.CANCELLED,
  ],
}
import store from "./redux/store";
import { updateWebSocketData } from "./redux/notification/actionCreator";
import privateKey from "./private.key";
import ca from "./ca.crt";
import cert from "./key.crt";
import { getItem } from './utility/localStorageControl';

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
  'browserid': getItem('browserId'),
});

const { io } = require("socket.io-client");
class WebSocketService {
  static instance = null;
  callbacks = {};

  constructor() {
    this.socketRef = null;
  }

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  connect() {
    this.socketRef = io("ws://localhost:4000", {
      extraHeaders: { ...authHeader() },
    });
    // this.socketRef = io("wss://api.mocdecor.org", {
    //   key: privateKey, cert, ca: ca,
    //   extraHeaders: { ...authHeader() },
    // });
    this.socketRef.on("connect", () => {
      console.log('WebSocket connected');
      setInterval(() => {
        this.socketRef.emit('health', { content: 'Ping from client' });
        // console.log('Sent ping to server');
        // Handle the ping event as needed
        // this.socket.emit('pong', 'Pong from server');
      }, 30000);
    });

    this.socketRef.on("webhook", (data) => {
      // console.log('Message received from server', data);
      store.dispatch(updateWebSocketData(data));
    });

    // this.socketRef.on("notification", (message) => {
    //   console.log('Receiver from server:', message);
    //   // Handle the ping event as needed
    // });

    this.socketRef.on("disconnect", () => {
      // console.log('WebSocket connection closed');
    });

    this.socketRef.on("error", (error) => {
      console.error('WebSocket error:', error);
    });
  }

  send(message) {
    // console.log('Prepare send message');
    // console.log(this.socket);
    // console.log(this.socket.connected);

    if (this.socketRef.connected) {
      // console.log('Sending message')
      this.socketRef.send(JSON.stringify(message));
    } else {
      console.error('WebSocket connection not open');
    }
  }

  disconnect() {
    console.log('Socket closed');
    this.socketRef.close();
  }
}

export default WebSocketService;

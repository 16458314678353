import { TIKTOK_ORDER_MANAGEMENT_TABS } from '../../helper/EnvEnum';
import { SET_FILTER, SET_TAB } from './actions';

const filter = {
  dateFrom: "",
  dateTo: "",
  status: "",
  progress: -1,
  urgent: false,
  sku: "",
  assignee: "",
};

const listFilter = {};
Object.entries(TIKTOK_ORDER_MANAGEMENT_TABS).forEach(([key, value]) => {
  listFilter[key] = { ...filter };
});

const initialState = {
  listFilter,
  tab: TIKTOK_ORDER_MANAGEMENT_TABS.ALL,
}

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        listFilter: action.payload,
      };
    case SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;

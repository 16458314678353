const actions = {
  GET_ALL_USERS_BEGIN: 'GET_ALL_USERS_BEGIN',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_ERR: 'GET_ALL_USERS_ERR',

  GET_SINGLE_USER_BEGIN: 'GET_SINGLE_USER_BEGIN',
  GET_SINGLE_USER_SUCCESS: 'GET_SINGLE_USER_SUCCESS',
  GET_SINGLE_USER_ERR: 'GET_SINGLE_USER_ERR',

  SEARCH_USERS_BEGIN: 'SEARCH_USERS_BEGIN',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  SEARCH_USERS_ERR: 'SEARCH_USERS_ERR',

  ADD_USER_BEGIN: 'ADD_USER_BEGIN',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_ERR: 'ADD_USER_ERR',

  UPDATE_USER_BEGIN: 'UPDATE_USER_BEGIN',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERR: 'UPDATE_USER_ERR',

  DELETE_USER_BEGIN: 'DELETE_USER_BEGIN',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERR: 'DELETE_USER_ERR',

  UPLOAD_AVATAR_BEGIN: 'UPLOAD_AVATAR_BEGIN',
  UPLOAD_AVATAR_SUCCESS: 'UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_ERR: 'UPLOAD_AVATAR_ERR',

  GET_EMPLOYEE_BEGIN: 'GET_EMPLOYEE_BEGIN',
  GET_EMPLOYEE_SUCCESS: 'GET_EMPLOYEE_SUCCESS',
  GET_EMPLOYEE_ERR: 'GET_EMPLOYEE_ERR',

  getAllUsersBegin: () => {
    return {
      type: actions.GET_ALL_USERS_BEGIN,
    };
  },

  getAllUsersSuccess: (data) => {
    return {
      type: actions.GET_ALL_USERS_SUCCESS,
      data
    };
  },

  getAllUsersErr: (err) => {
    return {
      type: actions.GET_ALL_USERS_ERR,
      err
    };
  },

  getSingleUserBegin: () => {
    return {
      type: actions.GET_SINGLE_USER_BEGIN,
    };
  },

  getSingleUserSuccess: (data) => {
    return {
      type: actions.GET_SINGLE_USER_SUCCESS,
      data
    };
  },

  getSingleUserErr: (err) => {
    return {
      type: actions.GET_SINGLE_USER_ERR,
      err
    };
  },

  searchUserBegin: () => {
    return {
      type: actions.SEARCH_USERS_BEGIN,
    };
  },

  searchUserSuccess: (data) => {
    return {
      type: actions.SEARCH_USERS_SUCCESS,
      data
    };
  },

  searchUserErr: (err) => {
    return {
      type: actions.SEARCH_USERS_ERR,
      err
    };
  },

  addUserBegin: () => {
    return {
      type: actions.ADD_USER_BEGIN,
    };
  },

  addUserSuccess: (data) => {
    return {
      type: actions.ADD_USER_SUCCESS,
      data
    };
  },

  addUserErr: (err) => {
    return {
      type: actions.ADD_USER_ERR,
      err
    };
  },

  updateUserBegin: () => {
    return {
      type: actions.UPDATE_USER_BEGIN,
    };
  },

  updateUserSuccess: (data) => {
    return {
      type: actions.UPDATE_USER_SUCCESS,
      data
    };
  },

  updateUserErr: (err) => {
    return {
      type: actions.UPDATE_USER_ERR,
      err
    };
  },

  deleteUserBegin: () => {
    return {
      type: actions.DELETE_USER_BEGIN,
    };
  },

  deleteUserSuccess: (data) => {
    return {
      type: actions.DELETE_USER_SUCCESS,
      data
    };
  },

  deleteUserErr: (err) => {
    return {
      type: actions.DELETE_USER_ERR,
      err
    };
  },

  uploadAvatarBegin: () => {
    return {
      type: actions.UPLOAD_AVATAR_BEGIN,
    };
  },

  uploadAvatarSuccess: (data) => {
    return {
      type: actions.UPLOAD_AVATAR_SUCCESS,
      data
    };
  },

  uploadAvatarErr: (err) => {
    return {
      type: actions.UPLOAD_AVATAR_ERR,
      err
    };
  },

  getEmployeeBegin: () => {
    return {
      type: actions.GET_EMPLOYEE_BEGIN,
    };
  },

  getEmployeeSuccess: (data) => {
    return {
      type: actions.GET_EMPLOYEE_SUCCESS,
      data,
    };
  },

  getEmployeeErr: (err) => {
    return {
      type: actions.GET_EMPLOYEE_ERR,
      err
    };
  },
}

export default actions;
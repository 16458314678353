import { API } from '../../config/api';
import { DataService } from '../../config/dataService/dataService';
import actions from './actions';

const {
  getAllUsersBegin,
  getAllUsersSuccess,
  getAllUsersErr,
  getSingleUserBegin,
  getSingleUserSuccess,
  getSingleUserErr,
  addUserBegin,
  addUserSuccess,
  addUserErr,
  updateUserBegin,
  updateUserSuccess,
  updateUserErr,
  deleteUserBegin,
  deleteUserSuccess,
  deleteUserErr,
  uploadAvatarBegin,
  uploadAvatarSuccess,
  uploadAvatarErr,
  getEmployeeBegin,
  getEmployeeSuccess,
  getEmployeeErr
} = actions;

const getAllUsers = (pageNumber, pageSize, status, search) => {
  return async (dispatch) => {
    try {
      dispatch(getAllUsersBegin());
      const response = await DataService.get(
        `${API.users.getAllUser}?page=${pageNumber}&limit=${pageSize}${status ? `&status=${status}` : ''
        }${search ? `&search=${search}` : ''
        }`);
      dispatch(getAllUsersSuccess(response.data));
    } catch (error) {
      dispatch(getAllUsersErr(error));
    }
  }
}

const getSingleUser = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(getSingleUserBegin());
      const response = await DataService.get(`${API.users.getSingleUser}${userId}`);
      dispatch(getSingleUserSuccess(response.data));
    } catch (error) {
      dispatch(getSingleUserErr(error));
    }
  };
}

const addUser = (data) => {
  return async (dispatch) => {
    return DataService.post(`${API.users.addUser}`, data);
  };
};

const updateUser = (userId, data) => {
  return async (dispatch) => {
    try {
      dispatch(updateUserBegin());
      const response = await DataService.patch(`${API.users.updateUser}${userId}`, data);
      dispatch(updateUserSuccess(response.data));
    } catch (error) {
      dispatch(updateUserErr(
        error.toString()
      ));
      return error.toString();
    }
  };
}

const deleteUser = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(deleteUserBegin());
      const response = await DataService.delete(`${API.users.deleteUser}${userId}`);
      dispatch(deleteUserSuccess(response.data));
    } catch (error) {
      dispatch(deleteUserErr(error));
    }
  };
}

const uploadAvatar = (data) => {
  return async (dispatch) => {
    try {
      dispatch(uploadAvatarBegin());
      const response = await DataService.upload(`${API.upload.uploadAvatar}`, data);
      dispatch(uploadAvatarSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(uploadAvatarErr(error));
    }
  }
}

const getEmployee = (pageNumber, pageSize) => {
  return async (dispatch) => {
    dispatch(getEmployeeBegin());
    const response = await DataService.get(`${API.users.getEmployee}?page=${pageNumber}&limit=${pageSize}`);
    dispatch(getEmployeeSuccess(response.data.users.records));
    return DataService.get(`${API.users.getEmployee}?page=${pageNumber}&limit=${pageSize}`);
  }
};

export {
  getAllUsers,
  getSingleUser,
  addUser,
  updateUser,
  deleteUser,
  uploadAvatar,
  getEmployee,
}
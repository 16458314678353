import actions from './actions';

const {
  GET_NOTIFICATION_LIST_BEGIN,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_ERR,
  READ_NOTIFICATION_BEGIN,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_ERR,
  UPDATE_WEBSOCKET_DATA
} = actions;

const initialStateFilter = {
  data: [],
  loading: false,
  error: null,
  websocketData: null,
  unreadNotification: 0,
};

const getNotificationListReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_NOTIFICATION_LIST_BEGIN:
      return {
       ...initialStateFilter,
        loading: true,
      };
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
       ...initialStateFilter,
        data: data.notifications,
        loading: false,
        unreadNotification: data.notificationUnread.length,
      };
    case GET_NOTIFICATION_LIST_ERR:
      return {
       ...initialStateFilter,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const readNotificationReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case READ_NOTIFICATION_BEGIN:
      return {
        ...state,
        data,
        loading: true,
      };
    case READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: data.notifications,
        loading: false,
        unreadNotification: data.notificationUnread.length,
      };
    case READ_NOTIFICATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_WEBSOCKET_DATA:
      return {
        ...state,
        websocketData: action.payload,
        unreadNotification: state.unreadNotification + 1,
      };
    default:
      return state;
  }
};

export { getNotificationListReducer, readNotificationReducer };

const actions = {
  GET_ALL_PRODUCTS_BEGIN: 'GET_ALL_PRODUCTS_BEGIN',
  GET_ALL_PRODUCTS_SUCCESS: 'GET_ALL_PRODUCTS_SUCCESS',
  GET_ALL_PRODUCTS_ERR: 'GET_ALL_PRODUCTS_ERR',

  SINGLE_PRODUCT_BEGIN: 'SINGLE_PRODUCT_BEGIN',
  SINGLE_PRODUCT_SUCCESS: 'SINGLE_PRODUCT_SUCCESS',
  SINGLE_PRODUCT_ERR: 'SINGLE_PRODUCT_ERR',

  FILTER_PRODUCT_BEGIN: 'FILTER_PRODUCT_BEGIN',
  FILTER_PRODUCT_SUCCESS: 'FILTER_PRODUCT_SUCCESS',
  FILTER_PRODUCT_ERR: 'FILTER_PRODUCT_ERR',

  SORTING_PRODUCT_BEGIN: 'SORTING_PRODUCT_BEGIN',
  SORTING_PRODUCT_SUCCESS: 'SORTING_PRODUCT_SUCCESS',
  SORTING_PRODUCT_ERR: 'SORTING_PRODUCT_ERR',

  SEARCH_PRODUCT_BEGIN: 'SEARCH_PRODUCT_BEGIN',
  SEARCH_PRODUCT_SUCCESS: 'SEARCH_PRODUCT_SUCCESS',
  SEARCH_PRODUCT_ERR: 'SEARCH_PRODUCT_ERR',

  GET_PRODUCT_ATTRIBUTE_BEGIN: 'GET_PRODUCT_ATTRIBUTE_BEGIN',
  GET_PRODUCT_ATTRIBUTE_SUCCESS: 'GET_PRODUCT_ATTRIBUTE_SUCCESS',
  GET_PRODUCT_ATTRIBUTE_ERR: 'GET_PRODUCT_ATTRIBUTE_ERR',

  singleProductBegin: () => {
    return {
      type: actions.SINGLE_PRODUCT_BEGIN,
    };
  },

  singleProductSuccess: (data) => {
    return {
      type: actions.SINGLE_PRODUCT_SUCCESS,
      data,
    };
  },

  singleProductErr: (err) => {
    return {
      type: actions.SINGLE_PRODUCT_ERR,
      err,
    };
  },

  filterProductBegin: () => {
    return {
      type: actions.FILTER_PRODUCT_BEGIN,
    };
  },

  filterProductSuccess: (data) => {
    return {
      type: actions.FILTER_PRODUCT_SUCCESS,
      data,
    };
  },

  filterProductErr: (err) => {
    return {
      type: actions.FILTER_PRODUCT_ERR,
      err,
    };
  },

  sortingProductBegin: () => {
    return {
      type: actions.SORTING_PRODUCT_BEGIN,
    };
  },

  sortingProductSuccess: (data) => {
    return {
      type: actions.SORTING_PRODUCT_SUCCESS,
      data,
    };
  },

  sortingProductErr: (err) => {
    return {
      type: actions.SORTING_PRODUCT_ERR,
      err,
    };
  },

  searchProductBegin: () => {
    return {
      type: actions.SEARCH_PRODUCT_BEGIN,
    };
  },

  searchProductSuccess: (data) => {
    return {
      type: actions.SEARCH_PRODUCT_SUCCESS,
      data,
    };
  },

  searchProductErr: (err) => {
    return {
      type: actions.SEARCH_PRODUCT_ERR,
      err,
    };
  },

  getAllProductBegin: () => {
    return {
      type: actions.GET_ALL_PRODUCTS_BEGIN,
    };
  },
  
  getAllProductSuccess: (data) => {
    return {
      type: actions.GET_ALL_PRODUCTS_SUCCESS,
      data,
    };
  },

  getAllProductErr: (err) => {
    return {
      type: actions.GET_ALL_PRODUCTS_ERR,
      err,
    };
  },

  getProductAttributeBegin: () => {
    return {
      type: actions.GET_PRODUCT_ATTRIBUTE_BEGIN,
    };
  },
  
  getProductAttributeSuccess: (data) => {
    return {
      type: actions.GET_PRODUCT_ATTRIBUTE_SUCCESS,
      data,
    };
  },

  getProductAttributeErr: (err) => {
    return {
      type: actions.GET_PRODUCT_ATTRIBUTE_ERR,
      err,
    };
  },
};

export default actions;
